import React from "react";
import { shallowEqual, useSelector } from "react-redux";
import styled from "styled-components";
import { Typography } from "antd";

import { SimpleEbOrder } from "types";
import { setSelectedSellTenor, setSelectedBuyTenor } from "store/spot/reducers";
import { useAppDispatch } from "store/store";
import { numberWithCommas } from "utils/numbers";
import { RootState } from "store/rootReducer";

const { Text } = Typography;

type Props = {
  order: SimpleEbOrder | null;
  type: "Sell" | "Buy";
};

const UnifiedSpotData: React.FC<Props> = ({ order, type }) => {
  const dispatch = useAppDispatch();
  const { SPOT_DECIMAL_PLACES } = useSelector(
    (state: RootState) => state.settings.parameters
  );
  const { traderId } = useSelector((state: RootState) => state.user);
  const { selectedBuyTenor, selectedSellTenor } = useSelector(
    (state: RootState) => ({
      selectedBuyTenor: state.spot.selectedBuyTenor,
      selectedSellTenor: state.spot.selectedSellTenor,
    }),
    shallowEqual
  );
  const isSell = type === "Sell";

  if (!order) return <></>;

  return (
    <div>
      <SpotTitle isSell={isSell}>SPOT</SpotTitle>

      <SpotRow
        selected={isSell ? !selectedSellTenor : !selectedBuyTenor}
        isSell={isSell}
        onClick={() => {
          const setSelectedTenor = isSell
            ? setSelectedSellTenor
            : setSelectedBuyTenor;
          dispatch(setSelectedTenor(undefined));
        }}
      >
        <Quantity
          style={
            order.traders?.includes(traderId) ? { color: "#007e4d" } : undefined
          }
        >{`${numberWithCommas(order.amount)} x`}</Quantity>
        <Price
          style={
            order.traders?.includes(traderId) ? { color: "#007e4d" } : undefined
          }
        >
          {order.price.toFixed(SPOT_DECIMAL_PLACES)}
        </Price>
      </SpotRow>
    </div>
  );
};

const SpotTitle = styled(Text)<{ isSell: boolean }>`
  display: block;
  font-weight: 600;
  text-align: ${(p) => (p.isSell ? "left" : "right")};
  font-family: Roboto, sans-serif;
`;

const Quantity = styled(Text)`
  font-size: 13px;
`;

const Price = styled(Text)`
  font-size: 18px;
`;

const SpotRow = styled.div<{ isSell: boolean; selected: boolean }>`
  padding: 0 5px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  border: 1px solid ${(p) => (p.selected ? p.theme.primary : "transparent")};
  border-radius: 3px;
  cursor: pointer;

  &:hover {
    background: #1f1f1f;
  }

  direction: ${({ isSell }) => (isSell ? "ltr" : "rtl")};
`;

export default UnifiedSpotData;
