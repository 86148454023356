import React from "react";
import { Button } from "antd";
import styled from "styled-components";

import { BID, ASK } from "stateConstants";
import { useAppDispatch } from "store/store";
import { cancelAllEbOrders } from "store/spot/actions";

type Props = {
  setMode: React.Dispatch<React.SetStateAction<0 | 1>>;
  setShowModal: React.Dispatch<React.SetStateAction<boolean>>;
};

const OrderButtons: React.FC<Props> = ({ setMode, setShowModal }) => {
  const dispatch = useAppDispatch();

  return (
    <Container>
      <PlaceButton
        size="small"
        onClick={() => {
          setMode(BID);
          setShowModal(true);
        }}
      >
        Place Bid
      </PlaceButton>
      <CancelAllButton
        size="small"
        danger
        onClick={() => {
          dispatch(cancelAllEbOrders());
        }}
      >
        Cancel All
      </CancelAllButton>
      <PlaceButton
        size="small"
        onClick={() => {
          setMode(ASK);
          setShowModal(true);
        }}
      >
        Place Ask
      </PlaceButton>
    </Container>
  );
};

const Container = styled.div`
  display: flex;
  gap: 5px;
  margin-top: 5px;
`;

const SpotButton = styled(Button)`
  border-color: transparent;
  border-radius: 3px;
  flex: 1;
`;

const PlaceButton = styled(SpotButton)`
  color: #b7eb8f95;
  border: 1px solid #b7eb8f95;

  transition: all 0.2s;

  &:hover {
    color: #b7eb8f !important;
    border: 1px solid #b7eb8f !important;
  }
`;

const CancelAllButton = styled(SpotButton)``;

export default OrderButtons;
